import { useRef, useState } from 'react';

import { useLocation } from '@remix-run/react';

import { AddressInput } from '@shared/address-input';
import { ContactMethods } from '@shared/contact-methods.tsx';
import { InputBlock } from '@shared/input-block';
import { Error, Fields, MainBtn, SecondaryBtn } from '@shared/styled';

import { useAuthedRequest } from '~/hooks/use-authed-request';

import type { Organization } from '~/types';
import { addHttps } from '~/utils/add-https';
import { event as GAEvent } from '~/utils/gtags.client';
import { isValidContactMethod } from '~/utils/has-valid-contact-method';
import { slugify } from '~/utils/slugify';
import { validateUrl } from '~/utils/validate-url';

import styles from '../event-form/form.module.css';

const defaultState: Organization = {
  address: null,
  name: '',
  nickname: '',
  website: '',
  contactMethods: [{ type: 'email', value: '' }],
};

export const OrganizationForm = ({
  organization,
  close,
  successCallback,
  errorCallback,
  createAsAdmin,
}: {
  close: () => void;
  successCallback: () => void;
  errorCallback?: (error: any) => void;
  createAsAdmin?: boolean;
  organization?: Organization;
}) => {
  const { pathname } = useLocation();
  const isFromOrg = pathname.includes('account/organizations');
  const [formState, setFormState] = useState<Organization>(organization || defaultState);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [submitError, setSubmitError] = useState('');
  const { post, patch } = useAuthedRequest(true);

  const { id, address, name, nickname, website, contactMethods } = formState;

  const isEditing = !!id;

  const handleSave = (data: Partial<Organization & { isAdmin?: boolean }>, callback: () => void) => {
    (id ? patch(`/organizations/${id}`, data) : post('/organizations', data))
      .then(() => {
        callback?.();
      })
      .catch((error) => {
        errorCallback?.(error);
        setSubmitError(error.response.data.message);
      });
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const newFormState: Partial<Organization> = hasChanged.current.reduce(
        (acc, key) => ({ ...acc, [key]: formState[key as keyof Organization] }),
        {},
      );

      const validContactMethods =
        (isEditing ? newFormState : formState)?.contactMethods?.filter(isValidContactMethod) || [];

      const data = isEditing
        ? { ...newFormState, ...(newFormState.contactMethods ? { contactMethods: validContactMethods } : {}) }
        : { ...formState, contactMethods: validContactMethods, isAdmin: createAsAdmin };
      handleSave!({ ...data, slug: slugify(name) }, () => {
        if (!isEditing) {
          setFormState(defaultState);
          setFormErrors({});
          successCallback();
          return;
        }
        successCallback();
        close();
      });
      GAEvent('submit_add_org_form');
    } else {
      GAEvent({ action: 'submit_add_org_form_error', value: JSON.stringify({ ...formErrors }) });
      setTimeout(() => {
        Array.from(document.querySelectorAll('.error-field'))[0].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      });
    }
  };

  const validateForm = () => {
    setSubmitError('');
    const errors: { [key: string]: string } = {};
    if (!name) {
      errors.name = 'Name is required.';
    }

    if (!address) {
      errors.address = 'Address is required.';
    }

    const validContactMethods = contactMethods?.filter(isValidContactMethod) || [];
    if (!validContactMethods.length) {
      errors.contactMethods = 'Please enter a preferred contact method.';
    }

    if (website && !validateUrl(website)) {
      errors.website = 'Please enter a valid URL.';
    }

    setFormErrors(errors);

    return !Object.keys(errors).length;
  };

  const hasChanged = useRef<string[]>([]);

  const handleFormChange = (newVal: Partial<Organization>) => {
    if (isEditing) {
      Object.keys(newVal).forEach((key) => {
        const castedKey = key as keyof Organization;
        if (
          (castedKey === 'address' && organization?.address?.id !== newVal.address?.id) ||
          (castedKey !== 'address' && JSON.stringify(organization?.[castedKey]) !== JSON.stringify(newVal[castedKey]))
        ) {
          if (!hasChanged.current.includes(castedKey)) {
            hasChanged.current = [...hasChanged.current, castedKey];
          }
        } else {
          hasChanged.current = hasChanged.current.filter((v) => v !== castedKey);
        }
      });
    }

    setFormState({ ...formState, ...newVal });
  };

  return (
    <>
      <Fields>
        <div className={`${styles.FieldWrapper} ${formErrors.name ? 'error-field' : ''}`}>
          <InputBlock
            label="Organization Name"
            required
            type="string"
            value={name}
            updateValue={(newVal) => handleFormChange({ name: newVal })}
          />
          {formErrors.name && <Error>{formErrors.name}</Error>}
        </div>
        <div className={styles.FieldWrapper}>
          <InputBlock
            label="Nickname"
            type="string"
            value={nickname!}
            updateValue={(newVal) => handleFormChange({ nickname: newVal })}
          />
        </div>

        <div className={`${styles.FieldWrapper} ${formErrors.address ? 'error-field' : ''} ${styles.fullWidth}`}>
          <AddressInput
            label="Location"
            required
            placeholder="Location (type address or city)"
            note={isFromOrg ? '' : 'The address or city of your organization'}
            value={address}
            updateValue={(newVal) =>
              handleFormChange({
                address: newVal,
                countryCode: newVal?.countryCode,
                countryName: newVal?.countryName,
                regionCode: newVal?.regionCode,
                regionName: newVal?.regionName,
              })
            }
          />
          {formErrors.address && <Error>{formErrors.address}</Error>}
        </div>

        <div className={`${styles.FieldWrapper} ${formErrors.contactMethods ? 'error-field' : ''} ${styles.fullWidth}`}>
          <ContactMethods
            contactMethods={contactMethods}
            updateMethods={(data) => handleFormChange({ contactMethods: data })}
          />
          {formErrors.contactMethods && <Error>{formErrors.contactMethods}</Error>}
        </div>
        <div className={`${styles.FieldWrapper} ${styles.fullWidth} ${formErrors.website ? 'error-field' : ''}`}>
          <InputBlock
            label="Website URL"
            prefix="https://"
            note="If your organization has a website for more information, add the URL here."
            type="string"
            value={(website || '').replace('https://', '').replace('http://', '')}
            updateValue={(newVal) => handleFormChange({ website: addHttps(newVal) })}
          />
          {formErrors.website && <Error>{formErrors.website}</Error>}
        </div>
      </Fields>

      {submitError && <Error>{submitError}</Error>}
      <div className={styles.ActionsWrapper}>
        <SecondaryBtn onClick={close}>Cancel</SecondaryBtn>
        <MainBtn onClick={handleSubmit}>{isEditing ? 'Submit Changes' : 'Submit Organization'}</MainBtn>
      </div>
    </>
  );
};
